// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `

  .custom-file-input {
    background-color: #e5e5e5; 
    color: black;
    padding: 8px;
    border: none;
    border-radius: 5px; 
    cursor: pointer;
    margin-left: 100;
  }
  

  .custom-file-label {
    padding: px; /* Adjust padding as needed */
  }

 `, "",{"version":3,"sources":["webpack://./src/static/css/AddProducts.css"],"names":[],"mappings":";;EAEE;IACE,yBAAyB;IACzB,YAAY;IACZ,YAAY;IACZ,YAAY;IACZ,kBAAkB;IAClB,eAAe;IACf,gBAAgB;EAClB;;;EAGA;IACE,WAAW,EAAE,6BAA6B;EAC5C","sourcesContent":["\n\n  .custom-file-input {\n    background-color: #e5e5e5; \n    color: black;\n    padding: 8px;\n    border: none;\n    border-radius: 5px; \n    cursor: pointer;\n    margin-left: 100;\n  }\n  \n\n  .custom-file-label {\n    padding: px; /* Adjust padding as needed */\n  }\n\n "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
