// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
 a {
    text-decoration: none;
    color: white;
    opacity: .8;
}

 a.active {
    color: white;
    opacity: 1;
}

.homeTitle {
    opacity: 1;
}

a #addButt {
    opacity: 1;
}

a #editButt {
    opacity: 1;
}

a:hover {
    color: white;
    opacity: 1;
}

.productCards {
 color: white;
 text-decoration: none;
 opacity: 1;
}

.productCards:hover {
 color: white;
 opacity: .8;
}
`, "",{"version":3,"sources":["webpack://./src/static/css/Navbar.css"],"names":[],"mappings":";CACC;IACG,qBAAqB;IACrB,YAAY;IACZ,WAAW;AACf;;CAEC;IACG,YAAY;IACZ,UAAU;AACd;;AAEA;IACI,UAAU;AACd;;AAEA;IACI,UAAU;AACd;;AAEA;IACI,UAAU;AACd;;AAEA;IACI,YAAY;IACZ,UAAU;AACd;;AAEA;CACC,YAAY;CACZ,qBAAqB;CACrB,UAAU;AACX;;AAEA;CACC,YAAY;CACZ,WAAW;AACZ","sourcesContent":["\n a {\n    text-decoration: none;\n    color: white;\n    opacity: .8;\n}\n\n a.active {\n    color: white;\n    opacity: 1;\n}\n\n.homeTitle {\n    opacity: 1;\n}\n\na #addButt {\n    opacity: 1;\n}\n\na #editButt {\n    opacity: 1;\n}\n\na:hover {\n    color: white;\n    opacity: 1;\n}\n\n.productCards {\n color: white;\n text-decoration: none;\n opacity: 1;\n}\n\n.productCards:hover {\n color: white;\n opacity: .8;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
